<script setup>
	// Libraries
	import { storeToRefs } from 'pinia';

	// Stores
	import { useHoursStore } from '@/stores/hours';

	// Store data
	const { special } = storeToRefs(useHoursStore());

	// Props
	const { style } = defineProps({
		style: {
			type: String,
			default: 'base',
		},
		showMessages: {
			type: Boolean,
			default: false,
		},
		showSpecialClosures: {
			type: Boolean,
			default: true,
		},
	});
</script>

<template>
	<div :class="`fragment-special-closures bg-white container style-${style}`">
		<div
			v-if="special.length && showSpecialClosures"
			class="special-closures"
			:class="`${showMessages ? 'has-messages' : ''}`"
		>
			<strong class="h5 text-red">special closures</strong>
			<ul>
				<li v-for="closure in special">
					<span class="hours">
						<p class="date" v-html="closure.dateString" />
						<template v-if="closure.centerClosed"><p class="">Closed all day</p></template>
						<template v-else-if="closure?.open && closure?.closed">
							<span class=""
								>Closed <span class="" v-html="closure.open" /> - <span class="" v-html="closure.closed"
							/></span>
						</template>
					</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<style lang="scss">
	.fragment-special-closures {
		margin: 2rem auto 0 auto;

		ul {
			display: flex;
			align-items: center;
			gap: 1rem;
			flex-wrap: wrap;

			li {
				font-size: inherit;
			}
		}

		strong {
			font-weight: 700;
			text-transform: uppercase;
			&.h6,
			&.h5 {
				margin-bottom: 0;
			}
		}

		.special-closures {
			display: flex;
			gap: 1rem;
			flex-direction: column;
			padding: 1rem 1.5rem;

			@media (min-width: $mobile) {
				flex-direction: row;
			}

			ul {
				align-items: center;
				li {
					.date {
						font-weight: 700;
					}

					.hours {
						display: flex;
						gap: 0.5rem;
					}
				}
			}
		}
	}
</style>
